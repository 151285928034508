import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import phone from '../../img/contact_phone.svg'
import email from '../../img/contact_email.svg'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
    let label = e.target.nextSibling;
    if (e.target.value == '')
    {
        label.classList.remove("hidden")
    }
    else
    {
        label.classList.add("hidden")
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section" style={{marginBottom: '80px'}}>
            <div className="container">
                <div className="content contact-page-container" style={{margin: '2rem', padding: '1rem'}}>
                <div className="columns">
                <div className="column is-2"></div>
                    <div className="column is-8">
                        <div className="content">
                            <h3 className="has-text-weight-semibold is-size-3 has-text-centered" style={{marginTop: '50px'}}>
                                Skontaktuj się ze mną
                            </h3>
                            <p className="has-text-centered">
                                Jeśli potrzebujesz wsparcia, jesteś na etapie startu lub po prostu potrzebujesz pomocy w realizacji celów, zapraszam na bezpłatną, indywidualną konsultację. Sprawdźmy w czym mogę Ci pomóc.
                            </p>
                        </div>
                    </div>
                    <div className="column is-2"></div>
                </div>

                <div className="columns">
                    <div className="column is-2"></div>
                    <div className="column is-4 has-text-centered contact-type">
                    <img src={phone} />
                    <h4>
                        +48 729 323 563 
                    </h4>
                    <p>
                        Poniedziałek - Piątek <br/>
                        16:00 - 20:00
                    </p>
                    </div>
                    <div className="column is-4 has-text-centered contact-type">
                    <img src={email} />
                    <h4>
                        krystynabronowicka@wp.pl
                    </h4>
                    <p>
                        Skontaktuj się ze mną bezpośrednio, lub użyj <span className="text-pink has-text-weight-semibold">formularza</span> w zakładce kontakt
                    </p>
                    </div>
                    <div className="column is-2"></div>
                </div>

                <div className="columns " >
                <div className="column is-2"> </div>
                    <div className="column is-8" style={{marginTop: '50px', marginBottom: '50px'}}>
                        <h3 className="has-text-weight-semibold is-size-3 has-text-centered">
                            Formularz kontaktowy
                        </h3>
                        <form
                            name="contact"
                            method="post"
                            action="/kontakt/thanks/"
                            data-netlify="true"
                            data-netlify-honeypot="bot-field"
                            onSubmit={this.handleSubmit}>
                            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                            <input type="hidden" name="form-name" value="contact" />
                            <div hidden>
                            <label>
                                Don’t fill this out:{' '}
                                <input name="bot-field" onChange={this.handleChange} />
                            </label>
                            </div>
                            
                            <div className="contact-form-field-container">
                                <div className="field">
                                    <div className="control">
                                        <input
                                        className="input"
                                        type={'text'}
                                        name={'name'}
                                        onChange={this.handleChange}
                                        id={'name'}
                                        required={true}
                                        />
                                        <label className="label" htmlFor={'name'}>
                                            Imię <span className="text-pink">*</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="field">
                                    <div className="control">
                                        <input
                                        className="input"
                                        type={'text'}
                                        name={'surname'}
                                        onChange={this.handleChange}
                                        id={'surname'}
                                        required={true}
                                        />
                                        <label className="label" htmlFor={'surname'}>
                                            Nazwisko <span className="text-pink">*</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="contact-form-field-container">
                                <div className="field">
                                    <div className="control">
                                        <input
                                        className="input"
                                        type={'email'}
                                        name={'email'}
                                        onChange={this.handleChange}
                                        id={'email'}
                                        required={true}
                                        />
                                        <label className="label" htmlFor={'email'}>
                                            Adres e-mail <span className="text-pink">*</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="field">
                                    <div className="control">
                                        <input
                                        className="input"
                                        type={'phone'}
                                        name={'phone'}
                                        onChange={this.handleChange}
                                        id={'phone'}
                                        required={true}
                                        />
                                        <label className="label" htmlFor={'phone'}>
                                            Numer telefonu
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-form-field-container">
                            <div className="field" style={{marginTop: '10px'}}>
                            <div className="control">
                                <textarea
                                className="textarea"
                                name={'message'}
                                onChange={this.handleChange}
                                id={'message'}
                                required={true}
                                />
                                <label className="label" htmlFor={'message'} style={{margin: 'unset', marginTop: '5px'}}>
                                    Wiadomość <span className="text-pink">*</span>
                                </label>
                            </div>
                            </div>
                            </div>
                            <div className="field has-text-centered" style={{marginTop: '50px'}}>
                            <button className="btn is-link" type="submit">
                                Wyślij
                            </button>
                            </div>
                        </form>
                    </div>
                    <div className="column is-2"> </div>
                </div>
                </div>
            </div>
        </section>
      </Layout>
    )
  }
}